import styles from './services.module.css';
import VerticalBlock from '../services-blocks/vertical';
import HorizontalBlock from '../services-blocks/horizontal';
import eCommerce from '../../images/services/ecommerce.svg';
import horizontalline from '../../images/services/horizontalline.svg';
import branddesign from '../../images/services/branddesign.svg';
import fulfillment from '../../images/services/fulfillment.svg';
import negotiations from '../../images/services/negotiations.svg';
import sourcing from '../../images/services/sourcing.svg';
import graphicdesign from '../../images/services/graphicdesign.svg';
import seo from '../../images/services/seo.svg';
import verticalline from '../../images/verticalline.png';
import oval from '../../images/services/oval.svg';
import ovalwithstrokes from '../../images/services/ovalwithstrokes.svg';
import smm from '../../images/services/smm.svg';
import { Parallax } from 'react-scroll-parallax';
// @ts-ignore
import Fade from 'react-reveal/Fade';

const Services = () => {
  return (
    <section className={styles.background} id="services">
      <div className={styles.relative__container}>
        <Fade duration={1000}>
          <h1 className={styles.section__title}>
            Choose your{' '}
            <span className={styles.text__highlighted}>services</span>
          </h1>
        </Fade>
        <div className={styles.first__oval}>
          <Parallax y={[-50, 50]} x={[-10, 10]}>
            <img src={oval} alt="Oval" />
          </Parallax>
        </div>
        <div className={styles.second__oval}>
          <Parallax y={[-50, 50]} x={[-10, 10]}>
            <img src={ovalwithstrokes} alt="Oval" />
          </Parallax>
        </div>{' '}
        <div className={styles.blocks__wrapper}>
          <div>
            <Fade duration={1000}>
              <div className={styles.blocks__first_row}>
                <VerticalBlock
                  img={eCommerce}
                  title={'eCommerce store creation'}
                  text={
                    'Make Money from Your Online Store with a Custom-Made Website and eCommerce Marketing.'
                  }
                  hasBorderRight={true}
                  hasBorderBottomOnMobile={true}
                  isLeftTop={true}
                />
                <VerticalBlock
                  img={branddesign}
                  title={'Brand Design'}
                  text={
                    'Issues with You May Be Experiences With Your Website and Our ECommerce Solutions'
                  }
                  hasBorderRight={true}
                  hasBorderBottomOnMobile={true}
                />
                <VerticalBlock
                  img={fulfillment}
                  title={'Fulfillment'}
                  text={
                    'The website lags and struggles to upload images, making it difficult to navigate — the sites.'
                  }
                  isRightTop={true}
                  hasBorderBottomOnMobile={true}
                />
              </div>
            </Fade>
          </div>
          <img
            src={horizontalline}
            alt="Horizontal line"
            className={styles.horizontal_border}
          />
          <Fade duration={1000}>
            <div className={styles.blocks__second_row}>
              <HorizontalBlock
                img={negotiations}
                title={'Contract negotiations'}
                text={
                  'The website is not optimized keywords to reel in the standard target audience and possible.'
                }
                hasBorderBottomOnMobile={true}
              />
              <img
                src={verticalline}
                className={styles.border__right}
                alt="Dotted line"
              />
              <HorizontalBlock
                img={seo}
                title={'SEO'}
                text={
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum semper.'
                }
                hasBorderBottomOnMobile={true}
              />
            </div>
          </Fade>
          <img
            src={horizontalline}
            alt="Horizontal line"
            className={styles.horizontal_border}
          />
          <Fade duration={1000}>
            <div className={styles.blocks__third_row}>
              <VerticalBlock
                img={sourcing}
                title={'Product sourcing'}
                text={
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum semper.'
                }
                hasBorderRight={true}
                hasBorderBottomOnMobile={true}
                isLeftBottom={true}
              />
              <VerticalBlock
                img={graphicdesign}
                title={'Graphic design'}
                text={
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum semper.'
                }
                hasBorderRight={true}
                hasBorderBottomOnMobile={true}
              />
              <VerticalBlock
                img={smm}
                title={'Social media management'}
                text={
                  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum semper.'
                }
                isRightBottom={true}
              />
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default Services;

import styles from './number.module.css';

interface NumberProps {
  number: number | string;
  title: string;
  text: string;
  color: string;
}

const Number = ({ number, title, text, color }: NumberProps) => {
  return (
    <div className={styles.number__block}>
      <h1 style={{ color }}>{number}</h1>
      <h6>{title}</h6>
      <p>{text}</p>
    </div>
  );
};

export default Number;

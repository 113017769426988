import styles from './block.module.css';
import verticalline from '../../images/verticalline.png';
import horizontalline from '../../images/horizontalline.png';

interface VerticalBlockProps {
  img: string;
  title: string;
  text: string;
  hasBorderRight?: boolean;
  hasBorderBottomOnMobile?: boolean;
  isLeftTop?: boolean;
  isLeftBottom?: boolean;
  isRightTop?: boolean;
  isRightBottom?: boolean;
}

const VerticalBlock = ({
  img,
  title,
  text,
  hasBorderRight,
  hasBorderBottomOnMobile,
  isLeftTop,
  isLeftBottom,
  isRightTop,
  isRightBottom,
}: VerticalBlockProps) => {
  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={styles.vertical_block__wrapper}
          style={{
            borderTopLeftRadius: isLeftTop ? 24 : 0,
            borderBottomLeftRadius: isLeftBottom ? 24 : 0,
            borderTopRightRadius: isRightTop ? 24 : 0,
            borderBottomRightRadius: isRightBottom ? 24 : 0,
          }}
        >
          <div className={styles.img__wrapper}>
            <img src={img} alt={title} />
          </div>
          <h6 className={styles.block__title}>{title}</h6>
          <p className={styles.block__text}>{text}</p>
        </div>
        {hasBorderRight && (
          <div className={styles.border__right}>
            <img src={verticalline} alt="Dotted line" />
          </div>
        )}
      </div>
      {hasBorderBottomOnMobile && (
        <div className={styles.border__bottom}>
          <img src={horizontalline} alt="Dotted line" />
        </div>
      )}
    </>
  );
};

export default VerticalBlock;

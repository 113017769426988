import styles from './clients.module.css';
import Client from './client';
import horizontalline from '../../images/horizontalline.png';
import shorthorizontal from '../../images/shorthorizontal.svg';
import instacart from '../../images/clients/instacart.svg';
import peach from '../../images/clients/peach.svg';
import amazon from '../../images/clients/amazon.svg';
import slack from '../../images/clients/slack.svg';
import shopify from '../../images/clients/shopify.svg';
import qualcomm from '../../images/clients/qualcomm.svg';
import reddit from '../../images/clients/reddit.svg';
import google from '../../images/clients/google.svg';
// @ts-ignore
import Fade from 'react-reveal/Fade';

const Clients = () => {
  return (
    <section className={styles.background} id="clients">
      <Fade duration={1000}>
        <h1 className={styles.section__title}>
          Our <span className={styles.text__highlighted}>clients</span>
        </h1>
        <div className={styles.rows__wrapper}>
          <div className={styles.row}>
            <Client
              img={instacart}
              client={'Instacart'}
              hasBorderRight={true}
            />
            <Client img={peach} client={'Peach'} hasBorderRight={true} />
            <Client img={amazon} client={'Amazon'} hasBorderRight={true} />
            <Client img={slack} client={'Slack'} hasBorderRight={false} />
          </div>
          <div className={styles.horizontal__border}>
            <img src={horizontalline} />
          </div>
          <div className={styles.row}>
            <Client img={shopify} client={'Shopify'} hasBorderRight={true} />
            <Client img={google} client={'Google'} hasBorderRight={true} />
            <Client img={qualcomm} client={'Qualcomm'} hasBorderRight={true} />
            <Client img={reddit} client={'Reddit'} hasBorderRight={false} />
          </div>
        </div>

        {/* Mobile layout */}

        <div className={styles.rows__wrapper__mobile}>
          <div className={styles.row}>
            <Client
              img={instacart}
              client={'Instacart'}
              hasBorderRight={true}
            />
            <Client img={peach} client={'Peach'} hasBorderRight={false} />
          </div>

          <div className={styles.horizontal__border}>
            <img src={shorthorizontal} />
          </div>

          <div className={styles.row}>
            <Client img={amazon} client={'Amazon'} hasBorderRight={true} />
            <Client img={slack} client={'Slack'} hasBorderRight={false} />
          </div>

          <div className={styles.horizontal__border}>
            <img src={shorthorizontal} />
          </div>

          <div className={styles.row}>
            <Client img={shopify} client={'Shopify'} hasBorderRight={true} />
            <Client img={google} client={'Google'} hasBorderRight={false} />
          </div>

          <div className={styles.horizontal__border}>
            <img src={shorthorizontal} />
          </div>

          <div className={styles.row}>
            <Client img={qualcomm} client={'Qualcomm'} hasBorderRight={true} />
            <Client img={reddit} client={'Reddit'} hasBorderRight={false} />
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default Clients;

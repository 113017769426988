import styles from './testimonial.module.css';
import quotes from '../../../images/testimonials/quotes.svg';
interface TestimonialProps {
  author: string;
  text: string;
  img: string;
  rating: string;
}

const Testimonial = ({ author, text, img, rating }: TestimonialProps) => {
  return (
    <div className={styles.testimonial__wrapper}>
      <div className={styles.quotes__wrapper}>
        <img src={quotes} alt="Quotes" />
      </div>
      <div className={styles.content__wrapper}>
        <div className={styles.content}>
          <p className={styles.content__text}>{text}</p>
          <div className={styles.author__info}>
            <div className={styles.author__info__inner__mobile}>
              <img src={img} alt="Author" />
              <h6>{author}</h6>
            </div>
            <p className={styles.rating__mobile}>{rating}</p>
          </div>
        </div>
        <p className={styles.rating__desktop}>{rating}</p>
      </div>
    </div>
  );
};

export default Testimonial;

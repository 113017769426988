import styles from './footer.module.css';
import { NavHashLink } from 'react-router-hash-link';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.copyright}>
        <p>© 2021 Torch</p>
      </div>
      <nav>
        <ul className={styles.menu__items}>
          <li>
            <NavHashLink
              smooth
              to="/#services"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              Services{' '}
            </NavHashLink>
          </li>

          <li>
            <NavHashLink
              smooth
              to="/#works"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              Works
            </NavHashLink>
          </li>
          <li>
            {' '}
            <NavHashLink
              smooth
              to="/#clients"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              Clients
            </NavHashLink>
          </li>
          <li>
            {' '}
            <NavHashLink
              smooth
              to="/#testimonials"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              Testimonials
            </NavHashLink>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;

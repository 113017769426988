import { useState } from 'react';
import styles from './header.module.css';
import logo from '../../images/logo.svg';
import burgermenu from '../../images/header/burgermenu.svg';
import closebtn from '../../images/header/close_btn.svg';
import { NavHashLink } from 'react-router-hash-link';
import { openPopupWidget } from 'react-calendly';

const Header = () => {
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  const handleMobileMenuOpened = (val: boolean) => {
    setMobileMenuOpened(val);
    const body = document.querySelector('body');
    if (!body) return;

    if (val === true) {
      body.className += 'prevent__scrolling';
    } else {
      body.classList.remove('prevent__scrolling');
    }
  };

  return (
    <header
      className={mobileMenuOpened ? styles.header__mobile : styles.header}
    >
      <div
        className={
          mobileMenuOpened
            ? styles.mobile__logomenu__wrapper
            : styles.logomenu__wrapper
        }
      >
        <div className={styles.logo__block}>
          <img src={logo} alt="Torch" />
        </div>

        {mobileMenuOpened ? (
          <div className={styles.close__menu}>
            <img
              src={closebtn}
              alt="Hide menu"
              onClick={() => handleMobileMenuOpened(false)}
            />
          </div>
        ) : (
          <div
            className={styles.mobile__menu}
            onClick={() => handleMobileMenuOpened(true)}
          >
            <img src={burgermenu} alt="Menu" />
          </div>
        )}
      </div>

      {mobileMenuOpened && (
        <div className={styles.mobile__menu__opened}>
          <nav className={styles.nav}>
            <ul className={styles.menu__items}>
              <li onClick={e => handleMobileMenuOpened(false)}>
                <NavHashLink
                  smooth
                  to="/#services"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  Services{' '}
                </NavHashLink>
              </li>
              <li onClick={e => handleMobileMenuOpened(false)}>
                <NavHashLink
                  smooth
                  to="/#works"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  Works
                </NavHashLink>
              </li>
              <li onClick={e => handleMobileMenuOpened(false)}>
                {' '}
                <NavHashLink
                  smooth
                  to="/#clients"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  Clients
                </NavHashLink>
              </li>
              <li onClick={e => handleMobileMenuOpened(false)}>
                {' '}
                <NavHashLink
                  smooth
                  to="/#testimonials"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  Testimonials
                </NavHashLink>
              </li>
            </ul>
            <div className={styles.cta__block__mobile}>
              <button
                className={styles.cta}
                onClick={() =>
                  openPopupWidget({ url: 'https://calendly.com/torchcommerce' })
                }
              >
                Contact Us
              </button>
            </div>
          </nav>
        </div>
      )}
      <div className={styles.menu}>
        <nav>
          <ul className={styles.menu__items}>
            <li>
              <NavHashLink
                smooth
                to="/#services"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                Services{' '}
              </NavHashLink>
            </li>

            <li>
              <NavHashLink
                smooth
                to="/#works"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                Works
              </NavHashLink>
            </li>
            <li>
              {' '}
              <NavHashLink
                smooth
                to="/#clients"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                Clients
              </NavHashLink>
            </li>
            <li>
              {' '}
              <NavHashLink
                smooth
                to="/#testimonials"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                Testimonials
              </NavHashLink>
            </li>
          </ul>
        </nav>
        <div className={styles.cta__block}>
          <button
            className={styles.cta}
            onClick={() =>
              openPopupWidget({ url: 'https://calendly.com/torchcommerce' })
            }
          >
            Contact Us
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;

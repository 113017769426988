import { useEffect, useState } from 'react';
import styles from './last.module.css';
import firstart from '../../images/last/first_art.svg';
import oval from '../../images/last/red_oval.svg';
import drops from '../../images/last/drops.svg';
import circle from '../../images/last/circle.svg';
import { Parallax } from 'react-scroll-parallax';
import { openPopupWidget } from 'react-calendly';
import { useInView } from 'react-intersection-observer';

function getScreenWidth() {
  const { innerWidth: width } = window;
  return width;
}

interface Props {
  setLastSectionEntered: (val: boolean) => void;
}

const LastBlock = ({ setLastSectionEntered }: Props) => {
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const { ref, inView, entry } = useInView();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(getScreenWidth());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (entry?.isIntersecting) {
      setLastSectionEntered(true);
    }

    if (!inView) {
      setLastSectionEntered(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry, inView]);

  return (
    <section className={styles.background} ref={ref}>
      <Parallax
        y={[-10, 20]}
        x={[-10, 15]}
        styleOuter={{
          position: 'absolute',
          top: '-30%',
          left:
            screenWidth > 768 ? '-15%' : screenWidth > 576 ? '-35%' : '-48%',
        }}
      >
        <img src={firstart} className={styles.firstart} />
      </Parallax>
      <Parallax
        y={[-5, 25]}
        x={[-5, 25]}
        styleOuter={{
          position: 'absolute',
          bottom: '10%',
          left: '15%',
        }}
        className={styles.circle__parallax}
      >
        {' '}
        <img src={circle} className={styles.circle} />
      </Parallax>
      <Parallax
        y={[-10, 30]}
        x={[-20, 25]}
        styleOuter={{
          position: 'absolute',
          top: '15%',
          right: '10%',
        }}
        className={styles.drops__parallax}
      >
        <img src={drops} className={styles.drops} />
      </Parallax>
      <Parallax
        y={[-10, 30]}
        x={[0, 40]}
        styleOuter={{
          position: 'absolute',
          bottom: screenWidth > 768 ? '-20%' : '-30%',
          right: '-5%',
        }}
      >
        <img src={oval} className={styles.oval} />
      </Parallax>
      <h1 className={styles.section__title}>Are you ready to work with us?</h1>
      <div className={styles.btn__wrapper}>
        <button
          className={styles.cta__button}
          onClick={() =>
            openPopupWidget({
              url: 'https://calendly.com/torchcommerce',
            })
          }
        >
          {' '}
          Let's talk
        </button>
      </div>
    </section>
  );
};

export default LastBlock;

import styles from './client.module.css';
import shortline from '../../../images/shortline.svg';

interface ClientProps {
  img: string;
  client?: string;
  hasBorderRight: boolean;
}

const Client = ({ img, client, hasBorderRight }: ClientProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.client__wrapper}>
        <img src={img} alt={client} />
      </div>
      {hasBorderRight && (
        <div className={styles.border__right}>
          <img src={shortline} alt="Dotted line" />
        </div>
      )}
    </div>
  );
};

export default Client;

import styles from './testimonials.module.css';
import Testimonial from './testimonial_desktop';
import testimonialsart from '../../images/testimonials/testimonialsart.svg';
import davidvon from '../../images/testimonials/david_von.svg';
import annvon from '../../images/testimonials/ann_von.svg';
import robertvon from '../../images/testimonials/robert_von.svg';
import horizontalline from '../../images/horizontalline.png';
import { Parallax } from 'react-scroll-parallax';
import '../../index.css';
import { Carousel } from 'react-responsive-carousel';
// @ts-ignore
import Fade from 'react-reveal/Fade';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Testimonials = () => {
  return (
    <section className={styles.background} id="testimonials">
      <div className={styles.testimonials__art}>
        <Parallax y={[30, -30]} x={[20, -20]}>
          <img src={testimonialsart} alt="forms" />
        </Parallax>
      </div>
      <Fade duration={1000}>
        <h1 className={styles.section__title}>
          Our <span className={styles.text__highlighted}>testimonials</span>
        </h1>
      </Fade>
      <Fade duration={1000}>
        <div className={styles.testimonials__wrapper__desktop}>
          <Testimonial
            author={'David Von'}
            text={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum semper imperdiet eget tristique pretium. Ut suspendisse ac.'
            }
            rating={'4.7/5'}
            img={davidvon}
          />
          <div className={styles.border__bottom}>
            <img src={horizontalline} alt="Dotted line" />
          </div>
          <Testimonial
            author={'Ann Von'}
            text={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum semper imperdiet eget tristique pretium. Ut suspendisse ac.'
            }
            rating={'4.7/5'}
            img={annvon}
          />
          <div className={styles.border__bottom}>
            <img src={horizontalline} alt="Dotted line" />
          </div>
          <Testimonial
            author={'Robert Von'}
            text={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum semper imperdiet eget tristique pretium. Ut suspendisse ac.'
            }
            rating={'4.7/5'}
            img={robertvon}
          />
        </div>
      </Fade>
      <div className={styles.testimonials__wrapper__mobile}>
        <Carousel showArrows={false} swipeable={true} showStatus={false}>
          <Testimonial
            author={'David Von'}
            text={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum semper imperdiet eget tristique pretium. Ut suspendisse ac.'
            }
            rating={'4.7/5'}
            img={davidvon}
          />
          <Testimonial
            author={'Ann Von'}
            text={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum semper imperdiet eget tristique pretium. Ut suspendisse ac.'
            }
            rating={'4.7/5'}
            img={annvon}
          />
          <Testimonial
            author={'Robert Von'}
            text={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum semper imperdiet eget tristique pretium. Ut suspendisse ac.'
            }
            rating={'4.7/5'}
            img={robertvon}
          />
        </Carousel>
      </div>
    </section>
  );
};

export default Testimonials;

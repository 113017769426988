import React, { useEffect, useState } from 'react';
import Header from '../header';
import styles from './firstblock.module.css';
import chat from '../../images/first/chat.svg';
import sparkle from '../../images/first/sparkle.svg';
import scribble from '../../images/first/scribble.svg';
import { openPopupWidget } from 'react-calendly';
// @ts-ignore
import Fade from 'react-reveal/Fade';

interface Props {
  lastSectionEntered: boolean;
}

const FirstBlock = ({ lastSectionEntered }: Props) => {
  const [buttonVisible, setButtonVisible] = useState(true);

  const changeButton = () => {
    if (window.scrollY >= 280) {
      setButtonVisible(false);
    } else {
      setButtonVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeButton);

    return () => window.removeEventListener('scroll', changeButton);
  }, []);

  return (
    <>
      <Header />
      <section className={styles.background}>
        <Fade duration={1000}>
          <div className={styles.svg__first}>
            <img src={sparkle} alt="Abstract" />
          </div>
        </Fade>
        <Fade duration={1000}>
          <div className={styles.svg__first__mobile}>
            <img src={sparkle} alt="Abstract" />
          </div>
        </Fade>
        <Fade duration={1000}>
          <div className={styles.text__wrapper}>
            <h1 className={styles.text}>
              Torch Commerce’s experience in{' '}
              <span style={{ whiteSpace: 'nowrap' }}>e-commerce</span> to
              convert clients
            </h1>
          </div>
        </Fade>
        <button
          className={buttonVisible ? styles.button : styles.button__shifted}
          style={{
            transform: lastSectionEntered
              ? 'translateY(1000px)'
              : 'translateY(0%)',
            transition: 'transform 0.3s ease-in-out',
          }}
          onClick={() =>
            openPopupWidget({
              url: 'https://calendly.com/torchcommerce',
            })
          }
        >
          {buttonVisible && <p> Let's talk </p>}
          <img className={styles.button__image} src={chat} alt="Chat" />
        </button>
        <button
          className={styles.button__mobile}
          onClick={() =>
            openPopupWidget({
              url: 'https://calendly.com/torchcommerce',
            })
          }
        >
          {' '}
          <p> Let's talk </p>{' '}
          <img className={styles.button__image} src={chat} alt="Chat" />
        </button>
        <Fade duration={1000}>
          <div className={styles.svg__second}>
            <img src={scribble} alt="Abstract" />
          </div>
        </Fade>
        <Fade duration={1000}>
          <div className={styles.svg__second__mobile}>
            <img src={scribble} alt="Abstract" />
          </div>
        </Fade>
      </section>
    </>
  );
};

export default FirstBlock;

import styles from './works.module.css';
import { useEffect, useRef } from 'react';
import firstcard from '../../images/works/1st.svg';
import secondcard from '../../images/works/2nd.svg';
import thirdcard from '../../images/works/3rd.svg';
import fourthcard from '../../images/works/4th.svg';
import fifthcard from '../../images/works/5th.svg';
import sixthcard from '../../images/works/6th.svg';
import seventhcard from '../../images/works/7th.svg';
import eightscard from '../../images/works/8th.svg';
import latestworksovals from '../../images/works/latestworks-ovals.svg';
import { Parallax } from 'react-scroll-parallax';

const firstRowImages = [firstcard, secondcard, thirdcard, fourthcard];
const secondRowImages = [fifthcard, sixthcard, seventhcard, eightscard];

const LatestWorks = () => {
  const firstRow = useRef(null);
  const secondRow = useRef(null);

  const scrollGrid = () => {
    const bodyHeight = document.body.offsetHeight;
    const mainHeight = document.querySelector('main')?.offsetHeight;
    if (!mainHeight) return;

    const translateYFirst =
      (window.pageYOffset / (mainHeight - bodyHeight)) * -140;
    const translateYSecond =
      (window.pageYOffset / (mainHeight - bodyHeight)) * 90;
    //@ts-ignore
    firstRow.current.style.setProperty('--scroll', translateYFirst + '%');
    //@ts-ignore
    secondRow.current.style.setProperty('--scroll', translateYSecond + '%');
  };

  useEffect(() => {
    window.addEventListener('resize', scrollGrid);
    window.addEventListener('scroll', scrollGrid);

    return () => {
      window.removeEventListener('resize', scrollGrid);
      window.removeEventListener('scroll', scrollGrid);
    };
  }, []);

  return (
    <section className={styles.background} id="works">
      <div className={styles.art}>
        <Parallax y={[40, -20]} x={[-20, 0]}>
          <img src={latestworksovals} alt="Forms" />
        </Parallax>
      </div>
      <h1 className={styles.section__title}>
        {' '}
        Latest <span className={styles.text__highlighted}>Works</span>
      </h1>
      <main>
        <div className={styles.works__wrapper}>
          <div className={styles.first__row} ref={firstRow}>
            {firstRowImages.map((el: string) => (
              <img src={el} key={el} alt="Work example" />
            ))}
          </div>
          <div className={styles.second__row} ref={secondRow}>
            {secondRowImages.map((el: string) => (
              <img src={el} key={el} alt="Work example" />
            ))}
          </div>
        </div>
      </main>
    </section>
  );
};

export default LatestWorks;

import styles from './block.module.css';
import horizontalline from '../../images/horizontalline.png';

interface HorizontalBlockProps {
  img: string;
  title: string;
  text: string;
  hasBorderBottomOnMobile?: boolean;
  hasBorderRight?: true;
}

const HorizontalBlock = ({
  img,
  title,
  text,
  hasBorderBottomOnMobile,
  hasBorderRight,
}: HorizontalBlockProps) => {
  return (
    <>
      <div className={styles.horizontal_block__wrapper}>
        <div className={styles.content__wrapper}>
          <h6 className={styles.block__title}>{title}</h6>
          <p className={styles.block__text}>{text}</p>
        </div>
        <div className={styles.img__wrapper}>
          <img src={img} alt={title} />
        </div>
      </div>
      {hasBorderBottomOnMobile && (
        <div className={styles.border__bottom}>
          <img src={horizontalline} alt="Dotted line" />
        </div>
      )}
    </>
  );
};

export default HorizontalBlock;

import { useState, useEffect, useLayoutEffect } from 'react';
import styles from './numbers.module.css';
import Number from './number';
import numbersart from '../../images/numbers/numbersart.svg';
import { useInView } from 'react-intersection-observer';
import { Parallax, ParallaxController } from 'react-scroll-parallax';
import { withController } from 'react-scroll-parallax';

function getScreenWidth() {
  const { innerWidth: width } = window;
  return width;
}

interface NumbersProps {
  parallaxController: ParallaxController;
}

const Numbers = ({ parallaxController }: NumbersProps) => {
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [solarProjects, setSolarProjects] = useState<number>(200);

  const [dollars, setDollars] = useState(200);
  const [kilowatt, setKilowatt] = useState(10);
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  useLayoutEffect(() => {
    const handler = () => parallaxController.update();
    window.addEventListener('load', handler);
    return () => window.removeEventListener('load', handler);
  }, [parallaxController]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(getScreenWidth());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let interval: any;
    if (entry?.isIntersecting) {
      interval = setInterval(() => {
        setSolarProjects(prevState =>
          prevState < 500 ? prevState + 1 : prevState
        );
        setDollars(prevState => (prevState < 480 ? prevState + 1 : prevState));
        setKilowatt(prevState => (prevState < 100 ? prevState + 1 : prevState));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [entry]);

  return (
    <section className={styles.background} ref={ref}>
      <div className={styles.art}>
        <Parallax
          y={[-20, 0]}
          x={[0, 30]}
          styleOuter={{
            position: 'absolute',
            right: screenWidth > 768 ? 0 : '-10%',
            bottom: screenWidth > 768 ? '35%' : '30%',
            zIndex: -1,
            height: '50%',
            margin: 'auto',
          }}
        >
          <img
            src={numbersart}
            alt="Heart shape"
            className={styles.heart__art}
          />
        </Parallax>
      </div>

      <h1 className={styles.section__title}>
        In <span className={styles.text__highlighted}>numbers</span>
      </h1>
      <div className={styles.numbers__wrapper}>
        <Number
          number={solarProjects + '+'}
          title={'Commercial'}
          text={'solar projects completed'}
          color={'#ff2a33'}
        />
        <Number
          number={dollars + 'k'}
          title={'Dollars'}
          text={'was saved for clients'}
          color={'#FFBC00'}
        />
        <Number
          number={kilowatt + 'm'}
          title={'Kilowatt evergy'}
          text={'was saved'}
          color={'#ff2a33'}
        />
      </div>
    </section>
  );
};

export default withController(Numbers);

import React, { useEffect } from 'react';
import Firstblock from './components/first';
import Services from './components/services';
import LatestWorks from './components/works';
import Clients from './components/clients';
import Numbers from './components/numbers';
import Testimonials from './components/testimonials';
import CTABlock from './components/last';
import Footer from './components/footer';
import { ParallaxProvider } from 'react-scroll-parallax';
import './index.css';

const App = () => {
  const [lastSectionEntered, setLastSectionEntered] = React.useState(false);
  return (
    <ParallaxProvider>
      <Firstblock lastSectionEntered={lastSectionEntered} />
      <Services />
      <LatestWorks />
      <Clients />
      <Numbers />
      <Testimonials />
      <CTABlock setLastSectionEntered={setLastSectionEntered} />
      <Footer />
    </ParallaxProvider>
  );
};

export default App;
